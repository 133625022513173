@import '../../Styles/Mixin.scss';

.loading-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    backdrop-filter: blur(20px);
    z-index: 10;
    svg {
        width: 50%;
        height: 50%;
        * {
            fill: $color_primary;
        }
    }
}

.empty-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 30px;
    backdrop-filter: blur(20px);
    z-index: 10;
    animation: fadeIn 1s;
    img {
        opacity: 0.8;
        max-height: 70%;
        margin-bottom: -60px;
    }
    p {
        font-size: 13px;
        margin: 0px;
        padding: 0px;
    }
    .title {
        color: rgba(#000, 0.2);
    }
    .description {
        color: rgba(#000, 0.6);
    }
}
