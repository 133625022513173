@import '../../../Styles/Mixin.scss';

.input-default-theme {
    display: flex;
    flex-direction: column;
    background: #f5f7f9;
    min-height: 50px;
    height: 50px;
    max-height: 50px;
    border-radius: 8px;
    display: flex;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    .label {
        position: absolute;
        top: -20px;
        font-size: 11px;
        margin: 0px;
        padding: 0px;
    }
    input {
        background: transparent;
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        color: $colorGray8;
    }
    button {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: transparent;
        animation: zoomIn 0.5s;
        svg {
            path {
                fill: $colorGray5;
            }
        }
    }
}
