@import '../../Styles/Mixin.scss';

#campanhas {
}

#detalhe-campanha {
    display: flex;
    flex-direction: column;
    .row-line {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .list-icons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        > li {
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: space-between;
            min-width: 230px;
            .icon {
                padding: 7px;
                border-radius: 10px;
                width: 45px;
                height: 45px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                svg {
                }
            }
            .button {
                position: absolute;
                > button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    box-sizing: border-box;
                    height: 45px;
                    width: 45px;
                    animation: zoomIn 0.5s;
                    &.warning {
                        background: $color_danger;
                        color: #fff;
                    }
                    &.success {
                        background: $color_success;
                        color: #fff;
                    }
                }
            }
            .text {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                span {
                    font-size: 13px;
                    color: $colorGray10;
                }
                b {
                    font-size: 18px;
                    color: $colorGray8;
                    font-weight: 600;
                    height: 27px;
                    width: 100%;
                    > span {
                        height: 100%;
                        opacity: 0.5;
                    }
                }
                .links {
                    display: flex;
                    gap: 10px;
                    a {
                        color: #fff;
                        font-size: 12px;
                        padding: 3px 10px;
                        border-radius: 100px;
                        background-color: $color_primary;
                    }
                }
            }
        }
    }
    .info-campgain {
        display: flex;
        flex: 1;
        min-height: 300px;
        background-color: #fff;
        background-image: url('/noise.png');
        justify-content: space-between;
        border-radius: 40px;
        box-shadow: $box-shadow;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        overflow: hidden;
        .campgain {
            padding: 40px;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 40px;
            position: relative;
            z-index: 2;
            padding-right: 250px;
            h1 {
                margin: 0px;
                padding: 0px;
                font-weight: 600;
                color: $colorGray3;
                line-height: 30px;
            }
            ul {
                li {
                    .icon {
                        border: 2px solid $color_primary;
                        color: $color_primary;
                    }
                }
            }
        }
        .img {
            height: 100%;
            max-height: 100%;
            min-width: 332px;
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 40px;
            position: absolute;
            right: 0px;
            bottom: -20px;
        }
    }
    .info-queue {
        background-image: url('/noise.png');
        display: flex;
        width: 35%;
        background-color: #e0e0e0;
        border-radius: 40px;
        box-shadow: $box-shadow;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        position: relative;
        overflow: hidden;
        padding: 40px;
        .loading-box {
            z-index: 3;
            border-radius: 40px;
        }
        .render-chart {
            width: 160px;
            height: 160px;
            z-index: 3;
            border-radius: 100px;
            overflow: hidden;
            backdrop-filter: blur(5px);
            padding: 20px;
            box-shadow: 0px 0px 10px rgba(#000, 0.1);
            animation: zoomIn 0.5s;
        }
        .img {
            width: 260px;
            height: 260px;
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0px;
            bottom: -20%;
            transform: scaleX(-1);
            z-index: 2;
        }
    }
    .box-white {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 20px;
        padding: 30px;
        flex: 1;
        position: relative;
        overflow: hidden;
        .head-box-white {
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 10;
            b {
                font-weight: 500;
            }
            .icon {
                width: 30px;
                height: 30px;
                background: #eaeaea;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    max-width: 20px;
                    max-height: 20px;
                }
            }
        }
        .total {
            display: flex;
            align-items: flex-end;
            line-height: 35px;
            b {
                font-size: 30px;
                font-weight: 500;
                color: $colorGray5;
            }
            span {
                color: $colorGray15;
                font-size: 13px;
                display: flex;
            }
        }
    }
}
