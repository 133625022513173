.timer-widget {
    display: flex;
    flex-direction: column;
    p {
        text-align: center;
        margin: 10px 0px !important;
        &.title {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
        }
        &.text {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .countdown {
        display: flex;
        justify-content: center;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
            position: relative;
            margin-bottom: 10px;
            b {
                font-size: 28px;
                min-width: 40px;
                padding:8px;
                box-sizing: border-box;
                line-height: 23px;
                letter-spacing: -2px;
                border-radius: 3px;
                position: relative;
                &:before {
                    width: 100%;
                    height: 50%;
                    content: '';
                    background-color: rgba($color: #000000, $alpha: .1);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 0;
                }
            }
            i {
                font-size: 30px;
                font-style: normal;
                margin: 0px 5px;
                font-weight: bold;
            }
            span {
                font-size: 8px;
                text-transform: uppercase;
                position: absolute;
                bottom: 0px;
                width: 100%;
                margin-bottom: -10px;
            }
        }
    }
}