@import '../../Styles/Mixin.scss';

#landing-pages {
    position: relative;
    #studio {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 28px 0px;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='15' height='15' fill='white' fill-opacity='.5'/%3E%3Cpath d='M1 16V1H16' stroke='black' stroke-opacity='0.05'/%3E%3C/svg%3E%0A");
        border-radius: 40px;
        padding: 30px;
        height: 100%;

        .plugin {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .center {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            gap: 50px;
            margin: 0px 20px;
            .input-name {
                background: #fff;
                border-radius: 10px;
                padding: 20px;
                width: 100%;
                box-sizing: border-box;
                box-shadow: $box-shadow;
            }
        }
    }
}

.widgets {
    border-radius: 40px;
    background: $colorWhite1;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: $box-shadow;
    width: 360px;
    .head {
        background-color: blackple;
        box-sizing: border-box;
        color: $colorGray10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(#000, 0.05);
        border-radius: 30px;
        position: relative;
        padding: 20px 30px;
        span {
            width: 1000%;
            font-size: 13px;
        }
        i {
            width: 100px;
            height: 60px;
            background: url(../../Images/icos/widgets-move-head.png) center left
                no-repeat;
            filter: invert(100%);
            position: absolute;
            right: -80px;
        }
    }
    .sections {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 81px);
        margin-top: 10px;
        .section-parent {
            .section {
                margin: 0px 0px 0px 0px;
                width: 100%;
                display: inline-block;
                box-sizing: border-box;
                p {
                    padding: 10px 14px;
                    font-size: 16px;
                    color: #939fb2;
                    margin: 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    &.opened {
                        background-color: rgba($color: #000000, $alpha: 0.01);
                        i {
                            transform: rotate(180deg);
                        }
                    }
                    &:hover {
                        background-color: rgba($color: #000000, $alpha: 0.05);
                    }
                    i {
                        font-size: 20px;
                        transition: all 0.2s;
                    }
                }
                > div {
                    padding: 4px;
                    width: 100%;
                    float: left;
                }
                .item {
                    float: left;
                    width: 90px;
                    height: 90px;
                    border-radius: 4px;
                    margin: 5px;
                    background-color: $colorGray20;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    cursor: move;
                    i {
                        width: 100%;
                        height: 29px;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-bottom: 5px;
                        &.column-1 {
                            background-image: url(../../Images/icos/column-1.png);
                        }
                        &.column-2 {
                            background-image: url(../../Images/icos/column-2.png);
                        }
                        &.column-3 {
                            background-image: url(../../Images/icos/column-3.png);
                        }
                        &.image {
                            background-image: url(../../Images/icos/widget-image.png);
                        }
                        &.offer {
                            background-image: url(../../Images/icos/widget-offer.png);
                        }
                        &.timer {
                            background-image: url(../../Images/icos/widget-timer.png);
                        }
                        &.text {
                            background-image: url(../../Images/icos/widget-text.png);
                        }
                        &.button {
                            background-image: url(../../Images/icos/widget-button.png);
                        }
                        &.form {
                            background-image: url(../../Images/icos/widget-form.png);
                        }
                        &.video {
                            background-image: url(../../Images/icos/widget-video.png);
                        }
                        &.map {
                            background-image: url(../../Images/icos/widget-map.png);
                        }

                        &.apple {
                            background-image: url(../../Images/icos/widget-apple.png);
                        }
                        &.playstore {
                            background-image: url(../../Images/icos/widget-playstore.png);
                        }
                        &.microsoft {
                            background-image: url(../../Images/icos/widget-microsoft.png);
                        }
                        &.facebook {
                            background-image: url(../../Images/icos/widget-facebook.png);
                        }
                        &.instagram {
                            background-image: url(../../Images/icos/widget-instagram.png);
                        }
                        &.whatsapp {
                            background-image: url(../../Images/icos/widget-whatsapp.png);
                        }
                        &.telefone {
                            background-image: url(../../Images/icos/widget-phone.png);
                        }
                        &.email {
                            background-image: url(../../Images/icos/widget-email.png);
                        }
                        &.sms {
                            background-image: url(../../Images/icos/widget-sms.png);
                        }
                    }
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
        .button-footer-widgets {
            display: flex;
            justify-content: space-between;
            padding: 20px 0px;
            border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
            width: 100%;
            gap: 20px;
            box-sizing: border-box;
        }
    }
}

.creation-layout {
    min-width: 305px;
    width: 305px;
    height: 570px;
    background: url(../../Images/mobile_frame.png);
    box-sizing: border-box;
    padding: 31px 16px 31px 11px;
    margin-left: 0px;
    transition: all 1s;
    > div {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fff;
        .item-renderized {
            padding: 4px 0px 0px 0px;
            > div:last-child {
                border: 1px solid #939fb2;
            }
            &.edit {
                border: 1px solid $color_warning;
            }
        }
        .item {
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #eaeaea;
            font-size: 12px;
            box-sizing: border-box;
        }
    }
}
.button-widget-edit {
    display: flex;
    justify-content: center;
    button {
        background-color: #939fb2;
        height: 20px;
        padding: 0px 4px;
        font-size: 10px;
        text-transform: lowercase;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        max-width: 100%;
        justify-content: space-between;
        img {
        }
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        &:first-child {
            margin-right: 1px;
            border-radius: 4px 0px 0px 0px;
        }
        &:last-child {
            border-radius: 0px 4px 0px 0px;
        }
        &:hover {
            opacity: 0.9;
        }
    }
}
.edition-widget {
    width: 300px;
    max-width: 360px;
    background: #fff;
    flex: 1;
    border-radius: 40px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .head {
        height: 71px;
        background-color: $colorGray20;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 30px;
        span {
            color: $colorGray10;
            font-weight: 600;
            width: 100%;
        }
        button {
            margin-right: 10px;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: $color_danger;
        }
    }
    .content {
        padding: 20px 0px;
        min-height: calc(100% - 71px);
        box-sizing: border-box;
        position: relative;
        overflow: auto;
        overflow-x: hidden;
        animation: backInLeft;
        animation-duration: 0.8s;
        form {
            animation: fadeIn;
            animation-duration: 1s;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            .ck-rounded-corners.ck-reset {
                background: rgba($color: #fff, $alpha: 0.8);
                .ck.ck-editor__main {
                    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
                }
                .ck-editor__editable {
                    text-align: unset !important;
                    padding-bottom: 10px;
                    background-color: transparent !important;
                }
            }
            .fieldsets {
                width: 100%;
                .group {
                    width: 100%;
                    display: flex;
                    border-bottom: 1px solid rgb(147, 159, 178);
                    padding-bottom: 10px;
                    &:first-child {
                        fieldset {
                            margin-top: 0px;
                        }
                    }
                    fieldset {
                        margin: 17px 0px 14px 0px;
                        width: 100%;
                    }
                    p {
                        margin: 10px 0px 0px 0px;
                    }
                }
                fieldset {
                    margin-bottom: 5px;
                    legend {
                        font-size: 14px;
                        color: #606a75;
                        margin-bottom: 6px;
                    }
                    &.full-input {
                        flex-direction: column;
                        > .inputs {
                            flex-direction: column;
                            > label {
                                width: 100%;
                                margin: 4px 0px 2px 0px;
                            }
                        }
                    }
                    &.upload-img {
                        border-bottom: 1px solid #939fb2;
                        .inputs {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            text-align: left;
                        }
                        .img {
                            width: 100%;
                            margin-bottom: 20px;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                        input {
                            display: none;
                        }
                        .upload {
                            display: flex;
                            align-items: center;
                            height: 34px;
                            box-sizing: border-box;
                            padding: 10px 15px;
                            background-color: #606a75;
                            border-radius: 100px;
                            font-weight: 600;
                            color: #fff;
                            cursor: pointer;
                            &:hover {
                                opacity: 0.8;
                            }
                            &:active {
                                opacity: 1;
                            }
                            i {
                                background: url(../../Images/icos/upload_icon.png)
                                    center no-repeat;
                                width: 20px;
                                height: 17px;
                                margin-left: 5px;
                            }
                        }
                        p {
                            font-size: 12px;
                            color: $colorGray5;
                        }
                    }
                    .inputs {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        &.full {
                            input[type='text'],
                            select {
                                width: 100% !important;
                            }
                        }
                        input[type='text'],
                        select {
                            width: 48%;
                            height: 30px;
                            display: flex;
                            flex-direction: column;
                            background: #f5f7f9;
                            min-height: 50px;
                            height: 50px;
                            max-height: 50px;
                            border-radius: 8px;
                            display: flex;
                            padding: 15px;
                            box-sizing: border-box;
                            position: relative;
                            box-sizing: border-box;
                        }
                        input[type='text'] {
                            display: flex;
                            flex-direction: column;
                            background: #f5f7f9;
                            min-height: 50px;
                            height: 50px;
                            max-height: 50px;
                            border-radius: 8px;
                            display: flex;
                            padding: 10px;
                            box-sizing: border-box;
                            position: relative;
                            box-sizing: border-box;
                        }
                        select {
                            color: #fff;
                            background-color: #939fb2;
                        }
                        input[type='range'] {
                            border: none;
                            width: 100%;
                        }
                        label {
                            &.color-widget {
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 14px;
                                    color: #606a75;
                                }
                                div {
                                    width: 15px;
                                    height: 15px;
                                    margin-left: 10px;
                                    border: 1px solid #fff;
                                    box-shadow: 0px 0px 0px 2px
                                        rgba($color: #000000, $alpha: 0.1);
                                    cursor: pointer;
                                    input {
                                        opacity: 0;
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }

                    /*especificos*/
                    &.label-font-size,
                    &.label-line-height {
                        .value-ranger {
                            width: 28px;
                            background-color: #fff;
                            border: 1px solid #939fb2;
                            font-size: 11px;
                            color: #939fb2;
                            box-sizing: border-box;
                            padding: 2px;
                            margin-right: 10px;
                        }
                    }
                    &.label-padding {
                        label {
                            display: flex;
                            flex-direction: column;
                            span {
                                font-size: 10px;
                                color: #606a75;
                            }
                            input {
                                width: 38px;
                                height: 21px;
                                background-color: #fff;
                                border: 1px solid #939fb2;
                                font-size: 11px;
                                color: #939fb2;
                                box-sizing: border-box;
                                padding: 2px;
                            }
                        }
                    }
                    &.label-text-align {
                        .inputs {
                            justify-content: flex-start;
                        }
                        button {
                            width: 22px;
                            height: 22px;
                            border: 1px solid #8899a1;
                            background-color: #fff;
                            color: #68737c;
                            cursor: pointer;
                            margin-left: 4px;
                            margin-top: 4px;
                            position: relative;
                            &.active {
                                background-color: #8899a1;
                                color: #fff;
                            }
                            div {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
            button[type='submit'],
            .button-default {
                width: 123px;
                height: 35px;
                border-radius: 100px;
                cursor: pointer;
                background-color: $color_warning;
                color: #606a75;
                font-weight: bold;
                margin-top: 10px;
            }
            .button-default-gray {
                background-color: #939fb2;
                padding: 3px 7px;
                font-size: 12px;
                color: #fff;
                cursor: pointer;
                i {
                    margin-left: 5px;
                }
            }
        }
    }
    .no-edition {
        text-align: center;
        padding: 10px 20px;
        p,
        a {
            text-align: left;
            color: $colorGray5;
            font-size: 13px;
            display: block;
            span {
                background-color: $color_primary;
                padding: 0px 5px;
                color: #fff;
            }
        }
        img {
            margin: 10px 0px;
        }
        a {
            color: $color_primary;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
