@import '../../Styles/Mixin.scss';

.paginator-clean {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    gap: 20px;
    position: relative;
    .total {
        font-size: 13px;
        color: $colorGray10;
        text-align: right;
        line-height: 15px;
    }
    > ul {
        display: flex;
        gap: 10px;
    }
}
