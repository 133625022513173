@import '../../Styles/Mixin.scss';

#login {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .box-login {
        background: #fff;
        border-radius: 40px;
        box-shadow: $box-shadow;
        display: flex;
        width: 1200px;
        position: relative;
        .left {
            width: 50%;
            height: 100%;
            padding: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-sizing: border-box;
            .box {
                width: 100%;
                display: flex;
                flex-direction: column;
                h1 {
                    margin: 0px;
                    font-weight: 500;
                    color: $colorGray5;
                    margin-top: 20px;
                }
                > p {
                    margin-top: 0px;
                    color: $colorGray15;
                }
                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                }
            }
        }
        .right {
            width: 50%;
            padding: 0px 50px 0px 100px;
            position: absolute;
            height: calc(100% + 100px);
            right: 0px;
            top: -50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            box-sizing: border-box;
            background: rgba($color_primary, 1);
            box-shadow: 0px 0px 20px 5px rgba(#000, 0.15);
            overflow: hidden;
            border-radius: 40px;
            animation: fadeInRight 0.5s;
            svg {
                path {
                    fill: #fff;
                }
            }
            p {
                max-width: 100%;
                color: $colorWhite1;
                //text-shadow: 0px 1px 3px rgba(#000, 0.4);
                font-size: 16px;
                line-height: 23px;
                position: relative;
                z-index: 2;
            }
            .img {
                width: 700px;
                height: 400px;
                display: flex;
                background: url('/dashboard-preview.png');
                background-size: 100% auto;
                box-shadow: 0px 0px 50px 50px rgba(#fff, 0.2);
            }
        }
    }
}
