@import '../../Styles/Mixin.scss';

#analytics {
    .tables {
        display: flex;
        gap: 25px;
        flex-direction: column;
        .header-children {
            form {
                display: flex;
                align-items: flex-end;
                gap: 25px;
            }
        }
    }
}
