@import './Mixin.scss';
.swal-overlay {
    z-index: 99999999;
    backdrop-filter: blur(5px);
    .swal-button--confirm {
        background-color: $color_primary;
        height: 45px;
        border-radius: 10px;
        &:hover {
            background-color: $color_primary;
            opacity: 0.9;
        }
        &:active {
            opacity: 0.95;
        }
    }
    .swal-modal {
        border-radius: 40px;
        box-shadow: $box-shadow;
        .swal-text {
            text-align: center;
            font-size: 14px;
        }
        .swal-footer {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            border-top: 1px solid rgba(#000, 0.1);
            background: rgba(#000, 0.05);
        }
    }

    .swal-icon--success__line--long,
    .swal-icon--success__line {
        background-color: $color_primary;
        cursor: pointer;
    }
    .swal-icon--success__ring {
        border: 4px solid $color_primary;
    }
}
