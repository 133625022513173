.buttonlink-widget {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-items: center;
    justify-content: center;
    i {
        font-size: 40px;
    }
    span {
        font-size: 12px;
    }
}