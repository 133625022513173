@import '../../Styles/Mixin.scss';

#usuario {
    .form-user {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        .flex-user {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
        .change-photo {
            width: 370px;
            height: 370px;
            display: flex;
            flex-direction: column;
            margin-left: 25px;
            background-size: cover;
            border-radius: 30px;
            display: flex;
            box-sizing: border-box;
            background-color: $color_primary;
            box-shadow: $box-shadow;
            .change-upload {
                display: none;
                width: 100%;
                height: 100%;
                padding: 40px;
                border-radius: 35px;
                box-sizing: border-box;
                overflow: hidden;
                .drag-drop-file {
                    background-color: rgba(#fff, 0.2);
                    backdrop-filter: blur(10px);
                    border-radius: 40px;
                    overflow: hidden;
                }
            }
            &:hover {
                .change-upload {
                    display: block;
                }
            }
        }
    }
}
