$color_warning: #f9cb46;
$color_danger: #f56464;
$color_primary: #820ad1;
$color_success: #61c454;

//new
$border-radius: 15px;
$border-radius-box: 40px;
$box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.03);

$baseWhite: #ffffff;
$baseGray: #f9faf5;

//texts
$colorGray1: rgba(#000, 1);
$colorGray2: rgba(#000, 0.95);
$colorGray3: rgba(#000, 0.9);
$colorGray4: rgba(#000, 85);
$colorGray5: rgba(#000, 0.8);
$colorGray6: rgba(#000, 0.75);
$colorGray7: rgba(#000, 0.7);
$colorGray8: rgba(#000, 0.65);
$colorGray9: rgba(#000, 0.6);
$colorGray10: rgba(#000, 0.55);
$colorGray11: rgba(#000, 0.5);
$colorGray12: rgba(#000, 0.45);
$colorGray13: rgba(#000, 0.4);
$colorGray14: rgba(#000, 0.35);
$colorGray15: rgba(#000, 0.3);
$colorGray16: rgba(#000, 0.25);
$colorGray17: rgba(#000, 0.2);
$colorGray18: rgba(#000, 0.15);
$colorGray19: rgba(#000, 0.1);
$colorGray20: rgba(#000, 0.05);

$colorWhite1: rgba(#fff, 1);
$colorWhite2: rgba(#fff, 0.95);
$colorWhite3: rgba(#fff, 0.9);
$colorWhite4: rgba(#fff, 85);
$colorWhite5: rgba(#fff, 0.8);
$colorWhite6: rgba(#fff, 0.75);
$colorWhite7: rgba(#fff, 0.7);
$colorWhite8: rgba(#fff, 0.65);
$colorWhite9: rgba(#fff, 0.6);
$colorWhite10: rgba(#fff, 0.55);
$colorWhite11: rgba(#fff, 0.5);
$colorWhite12: rgba(#fff, 0.45);
$colorWhite13: rgba(#fff, 0.4);
$colorWhite14: rgba(#fff, 0.35);
$colorWhite15: rgba(#fff, 0.3);
$colorWhite16: rgba(#fff, 0.25);
$colorWhite17: rgba(#fff, 0.2);
$colorWhite18: rgba(#fff, 0.15);
$colorWhite19: rgba(#fff, 0.1);
$colorWhite20: rgba(#fff, 0.05);
