@import '../../Styles/Mixin.scss';
.button-dark-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    position: relative;
    .button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: transparent;
        &.danger {
            color: $color_danger;
        }
        &.success {
            color: $color_success;
        }
    }
}

#darklist-modal {
    .foot-dk {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }
}
