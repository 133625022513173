@import '../../../Styles/Mixin.scss';

.checkbox-default-theme {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    p {
        margin: 0px;
        padding: 0px;
        font-size: 13px;
        color: $colorGray10;
    }
    .checkbox {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        background: $colorGray18;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            display: none;
            width: 20px;
            animation: zoomIn 0.5s;
        }
        &.active {
            background: $color_primary;
            color: #fff;
            svg {
                display: flex;
            }
        }
    }
}
