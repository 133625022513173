@import '../../../Styles/Mixin.scss';

.drag-drop-file {
    width: 100%;
    height: 100%;
    display: flex;

    form {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .input-file-upload {
            display: none;
        }
        .label-file-upload {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border: 2px dashed $colorGray15;
            background: $colorGray20;
            border-radius: 40px;
            transition: all 0.5s;
            padding: 30px 0px;
            &.drag-active {
                background-color: #ffffff;
                transform: scale(1.1);
                box-shadow: 0px 0px 10px 10px rgba(#000, 0.1);
            }
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                .icon {
                    background: rgba($color_primary, 0.2);
                    border-radius: 100px;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                }
                > p {
                    font-size: 13px;
                    margin: 0px;
                    color: $colorGray10;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 90%;
                }
            }
        }
        .drag-file-element {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 1rem;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    }
}
