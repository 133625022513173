@import '../../Styles/Mixin.scss';

.table-default-clean {
    width: 100%;
    background: $colorWhite1;
    border-radius: 40px;
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.01);
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .head {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
            font-size: 20px;
            font-weight: 500;
            color: $colorGray2;
            margin: 0px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .header-filter {
            display: flex;
            gap: 10px;
            max-height: 50px;
        }
    }
    .overflow {
        max-width: 100%;
        table {
            border-spacing: 0;
            width: 100%;
            th {
                text-align: left;
                color: $colorGray8;
                font-size: 13px;
                font-weight: 600;
                border-bottom: 2px solid $colorGray20;
                padding: 10px 10px;
                white-space: nowrap;
            }
            td {
                text-align: left;
                color: $colorGray14;
                font-size: 13px;
                padding: 10px 10px;
                .text-minify {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 200px;
                    cursor: pointer;

                    &:hover {
                        color: $color_primary;
                    }
                }
            }
            tbody {
                tr {
                    &:hover {
                        td {
                            background: rgba(#000, 0.02);
                        }
                    }
                }
            }
        }
    }
    .footer-table {
        border-top: 2px solid $colorGray20;
        padding-top: 30px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .config-checkbox {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-right: 20px;
            transition: all 0.2s;
            &.hidden {
                margin-top: -30px;
                opacity: 0;
            }
            > li {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10%;
                box-sizing: border-box;
                background: $colorGray20;
                border-radius: 10px;
                position: relative;
                cursor: pointer;
                &:before {
                    transition: all 0.2s;
                    content: '';
                    position: absolute;
                    right: 0px;
                    opacity: 0;
                    transition: all 0.2s;
                    top: calc(50% - 5px);
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 5px solid $colorGray20;
                }

                svg {
                    width: 70%;
                }
                &:after {
                    width: 3px;
                    height: 30px;
                    background: $colorGray20;
                    content: '';
                    position: absolute;
                    top: -30px;
                }
            }
            > ul {
                display: none;
                position: absolute;
                left: 50px;
                top: 0px;
                gap: 10px;
                animation: zoomIn 0.5s;
                > li {
                    padding: 10px 15px;
                    font-size: 13px;
                    cursor: pointer;
                    background: $colorGray18;
                    border-radius: 100px;
                    color: $colorGray10;
                    &:hover {
                        opacity: 0.9;
                    }
                    &:active {
                        opacity: 1c;
                    }
                }
            }

            &:hover {
                ul {
                    display: flex;
                }
                > li {
                    &:before {
                        opacity: 1;
                        right: -5px;
                    }
                }
            }
        }
    }
}
