@import '../../Styles/Mixin.scss';

#dashboard {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    .container-row {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0px;
        }
        .legend {
            display: flex;
            display: flex;
            gap: 10px;
            button {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                border-radius: 100px;
                gap: 8px;
                i {
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    display: flex;
                }
                span {
                    text-transform: uppercase;
                }
            }
        }
        .item {
            background-color: $baseWhite;
            border-radius: $border-radius-box;
            padding: 30px;
            min-width: none;
            box-shadow: 0px 10px 10px 10px rgba(#000, 0.01);
            display: flex;
            flex-direction: column;
            position: relative;
            min-width: 310px;
            flex: 1;
            @media (max-width: 1450px) {
            }
            &.pie {
                min-width: 250px;
            }
            &.line {
                width: calc(100%);
                padding: 30px 0px;
                .title {
                    padding: 0px 30px;
                }
                .content-item {
                    margin-bottom: -18px;
                }
            }
            &.activies {
                flex: 1;
            }
            &.banner {
                min-width: 250px;
                background-color: $color_primary;
                cursor: pointer;
                background-image: url('/noise.png');
                .banner-item {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0px;
                        padding: 0px;
                        color: #fff;
                    }
                    .title-banner {
                        font-size: 30px;
                    }
                    .description-banner {
                        font-size: 13px;
                        font-weight: 400;
                    }
                    .img-overlay {
                        position: absolute;
                        width: 320px;
                        z-index: 10px;
                        left: -50px;
                        bottom: -25px;
                        animation: fadeIn 1s;
                    }
                }
            }
            .title {
                font-size: 20px;
                margin: 0px;
                color: #28272c;
                font-weight: 500;
                position: relative;
                z-index: 11;
                span {
                    font-size: 12px;
                    color: rgba(#28272c, 0.5);
                }
            }
            .content-item {
                width: 100%;
                height: 230px;
            }
        }
    }
}
