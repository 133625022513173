.message-image {
    display: flex;
    min-height: 255px;
    height: 255px;
    flex-direction: column;
    justify-content: flex-end;
    .drag-drop-file {
        height: 100%;
        display: flex;
        max-height: 100%;
        min-height: 100%;
        .label-file-upload {
            border: none;
            border-radius: 10px;
            > div {
                display: flex;
                min-width: 280px;
            }
        }
    }
    .form-card {
        .drag-drop-file {
            height: auto;
            min-height: auto;
            max-height: auto;
        }
    }
}

.image-card {
    margin-top: 20px;
    .drag-drop-file {
        form {
            label {
                padding: 0px !important;
                align-items: flex-start;
                justify-content: flex-start;
                border: none;
                border-radius: 0px;
                background: none !important;
                > div {
                    align-items: flex-start;
                    padding-bottom: 0px;
                    width: 100%;
                }
            }
            .icon {
                display: none !important;
                &.load {
                    display: flex !important;
                    margin-bottom: 0px;
                }
            }
        }
    }
}
