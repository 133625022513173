@import '../../Styles/Mixin.scss';

.form-default {
    border-radius: 40px;
    padding: 30px;
    background-color: #fff;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex: 1;
    .head-form {
        display: flex;
        flex-direction: column;
        h1,
        h3 {
            margin: 0px;
            padding: 0px;
        }
        h1 {
            color: $colorGray5;
        }
        h3 {
            color: $colorGray10;
            font-weight: 500;
            font-size: 14px;
        }
    }
    .row-form {
        display: flex;
        gap: 25px;
        &.line {
            border-top: 1px solid rgba(#000, 0.1);
            padding-top: 15px;
        }
    }
}
