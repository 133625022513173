@import '../../Styles/Mixin.scss';

.blacklist-form {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    label {
        margin: 0px 0px;
    }
}

#blacklist-public {
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    p {
        text-align: center;
    }
}
