@import '../../../Styles/Mixin.scss';

.button-default-theme {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 13px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    svg {
        max-height: 25px;
    }
    &.icon {
        padding: 10px;
    }
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 0.95;
    }
    &.info {
        background: transparent;
        border: 1px solid $colorGray20;
        box-shadow: 0px 0px 10px rgba(#000, 0.1);
    }
    &.primary {
        background-color: $color_primary;
        color: $colorWhite1;
    }
    &.danger {
        background-color: $color_danger;
        color: $colorWhite1;
    }
    &.warning {
        background-color: $color_warning;
        color: $colorWhite1;
    }
    &.success {
        background-color: $color_success;
        color: $colorWhite1;
    }
}
