@import '../../Styles/Mixin.scss';

#layout-private {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    .container {
        display: flex;
        padding: 0px 0px;
        justify-content: space-between;
        box-sizing: border-box;
        width: 1;
        max-width: 100%;
        margin: 0px auto;
        height: 100vh;
        width: 1600px;
        max-width: 95%;
        > #sidebar {
            min-width: 300px;
            max-width: 300px;
            width: 300px;
            position: relative;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            background: $baseWhite;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            animation: fadeInLeft 0.5s;
            margin: 50px 0px;
            transition: all 0.5s;
            .padding-scroll {
                padding: 30px;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .logo {
                height: 105px;
                width: 100%;
                display: flex;
                border-bottom: 1px solid rgba(#000, 0.05);
                > a {
                    width: 100%;
                    flex: 1;
                    display: flex;
                    padding: 20px;
                    box-sizing: border-box;
                    justify-content: flex-start;
                    align-items: flex-start;
                    svg {
                        width: 163px;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .hamburger {
                    width: 40px;
                    background: none;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
            ul.menu-sidebar {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;
                &.pages {
                    flex: 1;
                }
                li {
                    display: flex;
                    flex-direction: column;
                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 5px;
                        border-radius: 10px;
                        &:hover {
                            .title {
                                color: $color_primary;
                            }
                        }

                        .icon {
                            min-width: 35px;
                            min-height: 35px;
                            height: 35px;
                            padding: 7px;
                            box-sizing: border-box;
                            color: $color_primary;
                            border-radius: 20px;
                            display: flex;
                            transition: all 0.2s;
                            &.active {
                                background: $color_primary;
                                color: $baseWhite;
                            }
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                            &.down {
                                box-shadow: unset;
                                background: rgba(#000, 0.05);
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: all 0.5s;
                                svg {
                                    width: 14px;
                                    height: 15px;
                                    margin-top: 1px;
                                }
                            }
                        }
                        .title {
                            width: 100%;
                            padding: 0px 10px;
                            font-size: 13px;
                            color: #817d7a;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 100%;
                        }
                    }
                    .submenu {
                        display: none;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 10px;
                        gap: 5px;
                        margin-left: 13px;
                        padding-left: 15px;
                        border-left: 2px dashed rgba(#000, 0.1);
                        animation: fadeInDown 0.5s;
                        &.opened {
                            display: flex;
                        }
                        .actived {
                            .item {
                                background: transparent !important;
                                .title {
                                    color: $color_primary !important;
                                }
                            }
                        }
                    }
                    &.actived {
                        > .item {
                            background-color: $color_primary;

                            .icon {
                                color: $baseWhite;
                            }
                            .title {
                                color: $baseWhite !important;
                            }
                            .down {
                                background-color: rgba(
                                    $baseWhite,
                                    0.1
                                ) !important;
                            }
                        }
                        ul {
                            .actived {
                                .item {
                                    .title {
                                        color: $color_primary;
                                    }
                                    .icon {
                                        background: $color_primary;
                                        color: $baseWhite;
                                    }
                                }
                            }
                        }
                    }
                    &.opened.normal {
                        > .item {
                            background: rgba(#000, 0.1) !important;
                        }
                        .down {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        > #content-page {
            min-width: calc(100% - 250px);
            margin-right: -50px;
            box-sizing: border-box;
            max-height: 100vh;
            transition: all 0.2s;
            #scroll-page {
                box-sizing: border-box;
                > div:first-child {
                    padding-top: 50px;
                    padding-bottom: 30px;
                }
                .content-padding {
                    width: calc(100% - 100px);
                    margin-right: 50px;
                    margin-left: 50px;
                    display: flex;
                    flex-direction: column;
                }
                #header-profile {
                    width: 100%;
                    display: flex;
                    position: relative;
                    height: 90px;
                    margin-bottom: 25px;
                    .position {
                        transition: all 0.2s;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        background: $baseWhite;
                        border-radius: $border-radius;
                        box-shadow: $box-shadow;
                        padding: 20px;
                        box-sizing: border-box;
                        gap: 20px;
                        .profile {
                            display: flex;
                            align-items: center;
                            .img {
                                width: 50px;
                                height: 50px;
                                border-radius: 100px;
                                background-size: cover;
                                background-color: rgba(#000, 0.5);
                                position: relative;
                                display: flex;
                                > div {
                                    overflow: hidden;
                                    border-radius: 100px;
                                    display: flex;
                                    > span {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                    }
                                }
                                &:after {
                                    width: 10px;
                                    height: 10px;
                                    content: '';
                                    border-radius: 100px;
                                    display: flex;
                                    position: absolute;
                                    bottom: 0px;
                                    right: 0px;
                                    background: #40c33e;
                                    border: 2px solid $baseWhite;
                                }
                            }
                            p {
                                margin: 0px;
                            }
                            .text {
                                display: flex;
                                flex-direction: column;
                                margin-left: 15px;
                                .name {
                                    font-weight: 700;
                                    font-size: 15px;
                                    color: #0e1840;
                                }
                                .account {
                                    font-size: 13px;
                                    color: #888888;
                                }
                            }
                        }
                        .menu-header {
                            border-left: 1px solid rgba(#000, 0.05);
                            padding-left: 25px;
                            display: flex;
                            justify-content: space-between;
                            flex: 1;
                            > div {
                                display: flex;
                                gap: 20px;
                            }
                            .button-default {
                                background: rgba(#000, 0.03);
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                padding: 8px 15px;
                                gap: 10px;
                                color: #0e1840;
                                position: relative;
                                svg {
                                    max-width: 20px;
                                    stroke: #0e1840;
                                }
                                span {
                                    font-size: 15px;
                                    display: flex;
                                    align-items: center;
                                }
                                &:hover {
                                    cursor: pointer;
                                    background: rgba(#000, 0.05);
                                }
                                .number {
                                    background: $color_primary;
                                    font-size: 10px;
                                    border-radius: 100px;
                                    color: $baseWhite;
                                    width: 20px;
                                    height: 20px;
                                    padding-top: 1px;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    top: -2px;
                                    right: -2px;
                                    border: 2px solid $baseWhite;
                                    svg {
                                        margin-top: -1px;
                                        * {
                                            stroke: $baseWhite;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.menu-fixed {
                    .position {
                        background: rgba($baseWhite, 0.8) !important;
                        backdrop-filter: blur(10px);
                        box-shadow: 0px 0px 10px rgba(#000, 0.1) !important;
                        position: fixed;
                        top: 0px;
                        z-index: 999;
                        border-radius: 0px 0px 15px 15px !important;
                    }
                }

                .header-page {
                    padding: 10px 0px;
                    margin: 0px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    height: 55px;
                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $colorGray20;
                        border-radius: 100px;
                        width: 35px;
                        height: 35px;
                        padding: 10px;
                        animation: fadeInLeft 0.5s;
                        color: rgba($baseWhite, 0.8);
                        background-color: $color_primary;
                        border: none;
                        position: relative;
                        overflow: hidden;
                    }
                    > button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: transparent;
                        cursor: pointer;
                    }
                    h1 {
                        font-weight: 500;
                        color: rgba(#000, 0.9);
                        margin: 0px;
                        padding: 0px;
                        height: 55px;
                        display: flex;
                        justify-content: flex-start;
                        height: 55px;
                        flex: 1;
                        align-items: center;
                        animation: fadeInRight 0.5s;
                        > span {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.mini {
            > #content-page {
                min-width: calc(100% - 50px);
            }
            #sidebar {
                min-width: 55px !important;
                width: 100px !important;
                .logo {
                    position: relative;
                }
                .hamburger {
                    right: -50px;
                    margin-top: 29px;
                    position: absolute;
                    z-index: 999;

                    svg {
                        margin: 0;
                    }
                }
                .item {
                    width: 35px;
                    .title {
                        width: 0px !important;
                        padding: 0px !important;
                    }
                    .down {
                        display: none !important;
                    }
                }
            }
        }
    }
}
