.phone-preview {
    width: 300px;
    height: 623px;
    background-image: url('/iphone-preview.png');
    background-size: 100% auto;
    position: relative;
    display: flex;
    flex-direction: column;
    .head-phone {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 18px 30px;
        position: relative;
        z-index: 2;
        > div {
            display: flex;
            gap: 10px;
            span {
                font-size: 13px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .content-phone {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        border-radius: 64px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 7px 9px;
        .children-item {
            width: 100%;
            height: 100%;
            .head-item {
                background-color: rgba(#000, 0.1);
                padding-top: 38px;
                padding-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    border-radius: 10px;
                    padding: 6px 20px;
                    background-color: rgba(#000, 0.1);
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
