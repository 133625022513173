.input-hours {
    display: flex;
    flex-direction: column;
    > p {
        font-size: 11px;
        margin: 0px;
        padding: 0px;
    }
    .element-input {
        background: #f5f7f9;
        min-height: 50px;
        height: 50px;
        max-height: 50px;
        border-radius: 8px;
        padding: 0px 8px;
        display: flex;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        .selects {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 5px;
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
        select {
            background-color: transparent;
        }
    }
}
