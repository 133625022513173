@import '../../../Styles/Mixin.scss';

.select-default-theme {
    display: flex;
    flex-direction: column;
    background: #f5f7f9;
    min-height: 50px;
    height: 50px;
    max-height: 50px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    position: relative;
    .label {
        position: absolute;
        top: -20px;
        font-size: 11px;
        margin: 0px;
        padding: 0px;
    }
    select {
        background: transparent;
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        color: $colorGray8;
    }
}
