@import '../../Styles/Mixin.scss';

.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s;
    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .modal-box-md {
        border-radius: 40px;
        background: #fff;
        box-shadow: $box-shadow;
        box-sizing: border-box;
        width: 680px;
        max-width: 90%;
        max-height: 80vh;
        z-index: 999;
        animation: zoomIn 0.5s;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .head-md {
            padding: 30px;
            display: flex;
            border-bottom: 1px solid rgba(#000, 0.1);
            width: 100%;
            box-sizing: border-box;
            align-items: center;
            p {
                margin: 0;
                padding: 0;
                flex: 1;
                font-size: 15px;
                color: $colorGray5;
            }
            .buttons {
                display: flex;
                margin-right: 20px;
                gap: 10px;
                button {
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    cursor: pointer;
                    border: 1px solid rgba(#000, 0.1);
                    &.close {
                        background: $color_danger;
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                    &.expand {
                        background: $color_success;
                        svg {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
        }
        .content {
            padding: 30px;
            max-height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .filter {
                gap: 20px;
                display: flex;
                flex-direction: column;
                max-height: 100%;
                .row {
                    display: flex;
                    gap: 30px;
                }
            }
        }
        .foot {
            display: flex;
            grid-gap: 20px;
            gap: 20px;
            box-sizing: border-box;
            border-radius: 0px 0px 40px 40px;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            background: rgba(0, 0, 0, 0.02);
            width: 100%;
            justify-content: center;
            padding: 30px;
        }
    }
}
