@import '../../Styles/Mixin.scss';
#nova-campanha {
    .list-steps {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 50px;
        .flex-step {
            box-sizing: border-box;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            max-width: 100%;
            .form-step {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                padding-right: 2px;
                &:last-child {
                    .container-step {
                        padding-top: 0px;
                        &::before {
                            top: auto;
                        }
                    }
                }
            }
            .container-step {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                position: relative;
                transition: all 0.5s;
                width: 100%;
                padding-top: 30px;
                overflow: hidden;
                &:before {
                    width: 0px;
                    height: 100%;
                    position: absolute;
                    content: '';
                    border-left: 1px dashed $color_primary;
                    border-right: 1px dashed $color_primary;
                    z-index: 1;
                    top: 0px;
                }
                .image-step {
                    display: none;
                    z-index: 2;
                    border-radius: 30px;
                    background-color: $color_primary;
                    background-image: url('/noise.png');
                    padding: 40px;
                    margin-bottom: 30px;
                    box-shadow: 0px 0px 20px rgba(#000, 0.1);
                    border: 3px solid rgba(#fff, 0.8);
                    img {
                        width: 100px;
                        max-width: 100px;
                        background-size: 80%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                .number-step {
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #eaeaea;
                    margin-bottom: -20px;
                    position: relative;
                    z-index: 3;
                }
                .tabs-message {
                    background-color: #fff;
                    display: flex;
                    flex: 1;
                    border-radius: 10px;
                    padding: 10px;
                    justify-content: space-between;
                    gap: 5px;
                    box-shadow: $box-shadow;
                    .tab {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 5px 20px;
                        border-radius: 10px;
                        cursor: pointer;
                        img {
                            max-height: 50px;
                        }
                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(#000, 0.5);
                        }
                        &.active {
                            background-color: $color_primary;
                            span {
                                color: #fff;
                            }
                        }
                    }
                }
                .form-card {
                    height: 255px;
                    .row {
                        display: flex;
                        gap: 10px;
                    }
                    .sugestions {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 15px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        margin-top: 10px;
                        gap: 10px;
                        > button {
                            padding: 10px 15px;
                            border-radius: 5px;
                            height: 50px;
                            max-height: 50px;
                        }
                        .item {
                            display: flex;
                            flex-direction: column-reverse;
                            align-items: center;
                            cursor: pointer;
                            transition: all 0.2s;
                            span {
                                display: flex;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 110px;
                            }
                            svg {
                                transition: all 0.2s;
                                path {
                                    fill: $color_danger;
                                }
                            }
                            &:hover {
                                transform: scale(1.1);
                                svg {
                                    transform: scale(1.4);
                                }
                            }
                        }
                        .new {
                            border-radius: 100px;
                            background-color: $color_warning;
                            color: white;
                            cursor: pointer;
                            span {
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .content-light {
                    width: 100%;
                    box-sizing: border-box;
                    border-radius: 20px;
                    padding: 30px;
                    background: $colorWhite1;
                    box-shadow: $box-shadow;
                    gap: 20px;
                    max-width: 100%;
                    position: relative;
                    z-index: 2;
                    .label-step {
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;
                        .select-options {
                            display: flex;
                            gap: 25px;
                            &.mt {
                                margin-top: 25px;
                            }
                        }
                        .progressbar {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            height: 40px;
                            border-radius: 100px;
                            overflow: hidden;
                            border: 1px solid rgba(#000, 0.1);
                            justify-content: center;
                            padding: 3px;
                            box-sizing: border-box;
                            position: relative;
                            .loading {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                background-color: rgba($color_warning, 1);
                                animation: fadeInRight 2s infinite;
                                border-radius: 100px;
                            }
                            > div {
                                background-color: rgba($color_success, 0.8);
                                display: flex;
                                height: 100%;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100px;
                                transition: all 0.2s;
                                span {
                                    animation: fadeInLeft 0.5s;
                                    position: absolute;
                                    background-color: $color_success;
                                    color: #fff;
                                    height: 60px;
                                    border-radius: 100px;
                                    bottom: -10px;
                                    box-sizing: border-box;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 20px;
                                    box-shadow: $box-shadow;
                                }
                            }
                        }
                        .calendar-campgain {
                            display: flex;
                            gap: 20px;
                            flex-direction: column;
                            background-color: #fff;
                            box-sizing: border-box;
                            padding: 20px;
                            border-radius: 30px;
                            box-shadow: $box-shadow;
                            .description {
                                color: $colorGray10;
                                margin: 0px;
                                padding: 0px;
                            }
                            .inputs {
                                display: flex;
                                gap: 20px;
                                width: 300px;
                            }
                        }
                        .send-preview {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            background: #fff;
                            border: 3px dashed rgba(#000, 0.2);
                            border-radius: 20px;
                            padding: 20px;
                            box-sizing: border-box;
                            max-width: 100%;
                            margin-top: 20px;
                            > p {
                                margin: 0px 0px;
                                font-size: 13px;
                            }
                            .form-destination {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-end;
                                gap: 20px;
                                .button-default-theme {
                                    width: 150px;
                                }
                            }
                            .list-destinations {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                background-color: rgba(#000, 0.05);
                                border-radius: 20px;
                                padding: 20px;
                                box-sizing: border-box;
                                ul {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-bottom: 20px;
                                    animation: fadeInDown 0.5s;
                                    gap: 10px;
                                    li {
                                        border-radius: 10px;
                                        display: flex;
                                        align-items: center;
                                        grid-gap: 10px;
                                        gap: 10px;
                                        padding: 5px 10px;
                                        border: 2px dashed rgba(0, 0, 0, 0.2);
                                        animation: zoomIn 0.5s;
                                        background: #fff;
                                        span {
                                            white-space: nowrap;
                                            font-size: 14px;
                                            color: rgba(0, 0, 0, 0.55);
                                        }
                                        button {
                                            border-radius: 100px;
                                            width: 30px;
                                            height: 30px;
                                            min-height: 30px;
                                            max-width: 30px;
                                            padding: 5px;
                                            box-sizing: border-box;
                                            display: flex;
                                            align-items: center;
                                            color: #fff;
                                            justify-content: center;
                                            cursor: pointer;
                                            background-color: $color_danger;
                                        }
                                    }
                                }
                            }
                        }
                        .message-campgain {
                            display: flex;
                            flex-direction: row;
                            gap: 40px;
                            width: 100%;
                            justify-content: space-between;
                            align-items: center;
                            .message-area {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                flex: 1;
                                width: calc(100% - 300px);
                                &.sms {
                                    .textarea-emoji textarea {
                                        height: 50px;
                                    }
                                }
                            }
                            .templates-preview {
                                display: flex;
                                flex-direction: column;
                                border: 2px dashed rgba(#000, 0.1);
                                border-radius: 10px;
                                padding: 20px;
                                p {
                                    font-size: 13px;
                                    color: $colorGray10;
                                    margin: 0px;
                                }
                                .list-templates {
                                    display: flex;
                                    width: 100%;
                                    height: 60px;
                                    box-sizing: border-box;
                                    margin-bottom: -15px;
                                    ul {
                                        display: flex;
                                        gap: 10px;
                                        box-sizing: border-box;
                                        padding-top: 5px;
                                        li {
                                            white-space: nowrap;
                                            background-color: rgba(#000, 0.2);
                                            padding: 10px 15px;
                                            margin-bottom: 10px;
                                            font-size: 12px;
                                            cursor: pointer;
                                            border-radius: 10px;
                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        }
                                    }
                                }
                            }
                            .preview {
                                max-height: 100%;
                                .messages {
                                    display: flex;
                                    flex-direction: column;
                                    padding: 20px;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    .message {
                                        background-color: rgba(#000, 0.1);
                                        padding: 15px;
                                        border-radius: 20px;
                                        font-size: 13px;
                                        color: #333;
                                        img,
                                        video {
                                            max-width: 100%;
                                        }
                                    }
                                    .card-message {
                                        display: flex;
                                        flex-direction: column;
                                        background-color: rgba(#000, 0.1);
                                        border-radius: 20px;
                                        img {
                                            max-width: 100%;
                                            border-radius: 20px 20px 0px 0px;
                                        }
                                        > p {
                                            padding: 0px 20px;
                                            margin: 0px;
                                            font-size: 13px;
                                            margin-top: 15px;
                                        }
                                        > span {
                                            opacity: 0.5;
                                            padding: 0px 20px;
                                            font-size: 12px;
                                            margin-bottom: 15px;
                                        }
                                        .buttons-sugestions {
                                            display: flex;
                                            flex-direction: column;
                                            padding: 0px 20px;
                                            a {
                                                color: $color_primary;
                                                &:last-child {
                                                    margin-bottom: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .label-clean {
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 20px;
                            padding: 20px;
                            box-sizing: border-box;
                            gap: 10px;
                            display: flex;
                            flex-direction: column;
                        }
                        .lists-selected {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 15px;
                            margin-top: 25px;
                            padding: 20px 0px;
                            background-color: rgba(#000, 0.05);
                            border-radius: 20px;
                            padding: 20px;
                            animation: fadeInDown 0.5s;
                            .list-info {
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                padding: 5px;
                                border: 2px dashed $colorGray17;
                                animation: zoomIn 0.5s;
                                background: #fff;
                                .icon-list {
                                    background: $colorGray20;
                                    min-width: 35px;
                                    border-radius: 5px;
                                    height: 35px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    svg {
                                        width: 25px;
                                        height: 25px;
                                    }
                                }
                                .name {
                                    white-space: nowrap;
                                    font-size: 14px;
                                    color: $colorGray10;
                                }
                                > button {
                                    border-radius: 100px;
                                    width: 30px;
                                    height: 30px;
                                    min-height: 30px;
                                    max-width: 30px;
                                    padding: 5px;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;

                                    color: #fff;
                                    justify-content: center;
                                    cursor: pointer;
                                    svg {
                                        width: 100%;
                                    }
                                }
                                .remove {
                                    background-color: $color_danger;
                                }
                                .edit {
                                    background-color: $color_primary;
                                }
                            }
                        }
                        > p {
                            margin: 0px;
                            font-size: 20px;
                            font-weight: 500;
                            margin-bottom: 20px;
                            color: $colorGray8;
                        }
                    }
                }
                &.active {
                    animation: fadeIn 0.5s;
                    .number-step {
                        border: 2px solid $color_primary;
                    }
                    .content-light {
                        border: 2px solid $color_primary;
                    }
                }
                &.checked {
                    animation: fadeInDown 0.5s;
                    &:before {
                        border-left: 1px dashed $color_success;
                        border-right: 1px dashed $color_success;
                    }
                    .image-step {
                        padding: 20px;
                        background-color: $color_success;
                    }
                    .image {
                        width: 65px;
                        height: 65x;
                    }
                    .content-light {
                        width: auto;
                        .label-step {
                            justify-content: center;
                            .title-label {
                                margin: 0;
                            }
                            div:nth-child(2),
                            div:nth-child(3) {
                                display: none;
                            }
                        }
                    }
                    .number-step {
                        border: 2px solid $color_success;
                        background-size: 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32" height="32" viewBox="0 0 32 32"%3E%3Cpath fill="currentColor" d="m13 24l-9-9l1.414-1.414L13 21.171L26.586 7.586L28 9L13 24z"%2F%3E%3C%2Fsvg%3E');
                    }
                    .content-light {
                        border: 2px solid $color_success;
                    }
                    &:after {
                        width: calc(100% - 30px);
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        content: '';
                        cursor: no-drop;
                    }
                }
                &.disabled {
                    opacity: 0.5;
                    transform: scale(0.9);
                    display: none;
                    margin-top: -30px;
                    &:after {
                        width: calc(100% - 30px);
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        content: '';
                        cursor: no-drop;
                    }
                }
            }
            .foot-step-light {
                display: flex;
                margin-top: 30px;
                gap: 20px;
                border-top: 1px solid rgba(#000, 0.1);
                padding: 20px 0px;
            }
        }
    }
}
